<template>
  <ag-modal-container
    title="Ordres de fabrications clôturés"
    width="900px"
    @close="back"
  >
    <div
      v-if="!loadingData && !batchList.length"
      class="text-center title mt-5"
    >
      Aucun élément
    </div>

    <batch-list
      v-else
      :value="batchList"
      :selected.sync="selectedBatchList"
      :loading="loadingData"
      light
    />

    <template v-slot:footer>
      <v-spacer />
      <ag-confirm-dialog
        title="Confirmation"
        :message="restoreMessage"
        cancel-label="Annuler"
        confirm-label="Restaurer"
        validation-btn-color="accent"
        @confirm="restoreSelected"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingRestore"
            :disabled="!selectedBatchList.length || loadingRestore"
            color="accent"
            class="ml-5"
            v-bind="attrs"
            v-on="on"
            v-text="'Restaurer'"
          />
        </template>
      </ag-confirm-dialog>
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import BatchList from '../components/BatchList'

export default {
  name: 'BatchRestoreContainer',
  components: {
    AgModalContainer,
    AgConfirmDialog,
    BatchList,
  },
  data () {
    return {
      batchList: [],
      selectedBatchList: [],
      loadingData: false,
      loadingRestore: false,
    }
  },
  computed: {
    restoreMessage () {
      const count = this.selectedBatchList.length
      return `Souhaitez-vous restaurer ${count} ordre(s) de fabrication(s)`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler ({ name }) {
        if (name === 'BatchRestore') {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async fetchData () {
      this.loadingData = true
      this.batchList = await Repositories.batch.getList({ archived: true })
      this.loadingData = false
    },
    async restoreSelected () {
      try {
        this.loadingRestore = true
        const count = this.selectedBatchList.length
        await Repositories.batch.restore(
          this.selectedBatchList.map(select => select._id),
        )

        this.selectedBatchList = []
        this.fetchData()

        this.showSuccessNotification(`Vous avez restoré ${count} ordre(s) de fabrication(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingRestore = false
      }
    },
  },
}
</script>
